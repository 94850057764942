import React, { useEffect, useState, useRef } from 'react';
import { deleteLSBC } from '../../Service/LSBCService';
import { createITR, getPlanProdAll } from '../../Service/PlanProdService';
import Overlay from '../../Components/Overlay/Overlay';
import { Dialog } from 'primereact/dialog';
import Swal from 'sweetalert2';
import MTable from '../../Components/MTable/MTable';
import { useNavigate } from 'react-router-dom';
import { getDiagram, getHistories } from '../../Service/WorkflowService';
import '../../../src/App.css';

const PlanProdScreen = () => {
    const navigate = useNavigate();
    const mTable = useRef();
    const isCompany = localStorage.getItem('selectedCompany');
    const [processing, setProcessing] = useState(false)
    
    useEffect(() => {}, []); 
    const [show, setShow] = useState(false);
    const [histories, setHistories] = useState([]);

    const onView = item => () => {
        navigate("view/" + item.id);
    };

    const onViewMFI = item => () => {
        navigate("viewMFI/" + item.id);
    };

    const onEdit = item => () => {
        navigate("edit/" + item.id);
    };

    const onEditMFI = item => () => {
        navigate("editMFI/" + item.id);
    };

    const onSubmit = (item) => {
        
        let flag = true;
        const not_validated = [
            "statusititr",
            "statusapproval",
            "incident_id",
            "transferqty",
            "active_task_id",
            "active_task_name",
            "active_task_nik",
            "updatedby"
        ]

            if (item.assytype === "CBU") {
                for (let key in item) {
                    if (!not_validated.includes(key)&& item[key] === "") {
                        console.log(key);
                        flag = false;
                        break;
                    }
                }
            } else {
                flag = false; 
            }
            
    
        if (!flag) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please complete the blank fields !',
                showCancelButton: true
            });
            return;
        
        }

        Swal.fire({
            title: 'Are you sure ?',
            confirmButtonText: 'OK',
            showCancelButton: true,
            text: `Do you want to Transfer stock : ${item.prodqty} to warehouse : ${item.destinationwhs}`,
            denyButtonText: 'No',
            customClass: {
              actions: 'my-actions',
              confirmButton: 'order-2',
              cancelButton: 'order-1 right-gap',
            },
          }).then((result) => {
            if (result.isConfirmed) {
                setProcessing(true);
                createITR(item.tr_iqcheader_id, item.id, item.statusqc).then(res => {
                    setProcessing(false);
                    if(res.data.status == 200 || res.status == 201){
                        // Swal.fire('Success', '', 'Create ITR Success').then(_ => {
                        //     mTable.current.refresh();
                        // })
                        Swal.fire({
                            icon: 'success',
                            title:'Create ITR success !',
                            text: 'Data has been changed!'
                        }).then(_ => {
          
                            mTable.current.refresh();
                        });
                    } else {
                        setProcessing(false);
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: "Failed to Create ITR"
                        }).then(_ => {
          
                            mTable.current.refresh();
                        });
                    }
                  
                }).catch(err => {
                    setProcessing(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: "Failed to Create ITR while inject data to SAP"
                    }).then(_ => {
              
                        mTable.current.refresh();
                    });
                })
               
            } 
          })
    };

    const onRemove = item => () => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'Deleted data can not be restored!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeData(item.id);
            }
        });
    }

    const removeData = id => {
        deleteLSBC(id, res => {
            if (res.status == 200 || res.status == 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Delete data success',
                    text: 'Data has been deleted!'
                }).then(res => { mTable.current.refresh(); });
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Delete data fail',
                text: 'Data can not be deleted!'
            });
        });
    }
    const openApproval = (item) => {
        //test
        if(item.incident_id != null) {
            getHistories(item.incident_id).then(res => {
                setHistories(res.data.histories);
            });

            getDiagram(item.incident_id).then(res => {
                document.getElementById('approval-iframe').srcdoc = res.data;
            });
        }

        setShow(true);
    }
    
    const columns = [
        { id: 1, title: 'Item Code', field: 'itemcode', sortable: true },
        { id: 4, title: 'Assy Type', field: 'assytype', sortable: true },
        { id: 5, title: 'Model', field: 'itemname', sortable: true },
        { id: 6, title: 'Lot Order', field: 'lotorder', sortable: true },
        { 
            id: 7, title: 'Planning Priority', 
            ...(isCompany === 'MI' ? { field: 'planpriority' } : { field: 'ppdplanpriority' })
            , sortable: true 
        },
        { id: 8, title: 'Warehouse Stock', field: 'instock', sortable: true, 
             render: item => {
                return (
                    <div>
                        <tr>
                            {item.instock ? item.instock : ''}
                        </tr>
                    </div>
                )
             }
        },
        { 
            id: 9, title: 'ST AVE', 
            ...(isCompany === 'MI' ? {field: 'st_ave'} : {field: 'msst_ave'}) 
            , sortable: true 
        },
        { 
            id: 10, title: 'Operator',
            ...(isCompany === 'MI' ? {field: 'operator'} : {field: 'ppdoperator'})
            , sortable: true 
        },
        { 
            id: 11, title: 'Target', 
            ...(isCompany === 'MI' ? {field: 'target'} : {field: 'ppdtarget'}), 
            sortable: true },
        { 
            id: 13, title: 'KBT QTY', 
            ...(isCompany === 'MI' ? {field: 'kbtqty'} : {field: 'prodqty'}),
            sortable: true
        },
        { 
            id: 13, title: 'Open QTY', 
            ...(isCompany === 'MI' ? {field: 'prodqtymi'} : {field: 'openqty'}),
            sortable: true
        },
        { 
            id: 13, title: 'Group',
            ...(isCompany === 'MI' ? {field: 'prodgroup'} : {field: 'ppdgroup'}),
            sortable: true 
        },
        { 
            id: 14, title: 'Process Time /Min',
            ...(isCompany === 'MI' ? {field: 'processmin'} : {field: 'ppdprocessmin'}),
            sortable: true 
        },
        { 
            id: 15, title: 'Preparation Time /Min',
            ...(isCompany === 'MI' ? {field: 'prepmin'} : {field: 'ppdprepmin'}),
            sortable: true 
        },
        { 
            id: 16, title: 'Total Process Time /Min',
            ...(isCompany === 'MI' ? {field: 'totprocessmin'} : {field: 'ppdtotprocessmin'}), 
            sortable: true 
        },
        { id: 17, title: 'Status QC', field: 'statusqc', sortable: true},
        { id: 18, title: 'Destination Warehouse', field: 'destinationwhs', sortable: true },
        { id: 18, title: 'Status Last Approval', field: 'statusapprove', sortable: true },
        { id: 1, title: 'Document Date', field: 'productiondate', sortable: true },
        { id: 20, title: 'Status IT/ITR', field: 'statusititr', sortable: true, 
            render: item => {
                return(
                    <div>
                        {!item.statusititr ? (
                            <tr>
                                -
                            </tr>
                        ) : item.statusititr}

                    </div>
                )
            }
        },
        {
            id: 21,
            title: 'Action',
            render: item => {
                let whs = item.destinationwhs.replace("WH-", '');
                let itemCode = item.itemcode;
                // Get the last three characters
                const lastThreeChars = itemCode.slice(-3);

                // Get the first character from the last three characters
                const firstCharFromLastThree = lastThreeChars[0];

                console.log(firstCharFromLastThree); // Output: B
                // console.log('ini itemcode', item.itemcode);
                return (
                    <div>
                        {(item.assytype === "CBU" && whs == "JTPRD") && (item.statusititr === "COMPLETED") ? (
                    <>
                        <a
                            onClick={onView(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" />
                        </a>
                    </>
                ) : (((item.assytype === "CBU" && whs == "JTFGA") || (item.assytype === "CBU" && whs == "SDPRD") || (item.assytype === "CBU" && whs == "SDFGA")) && (item.statusititr === "COMPLETED") ? (
                    <>
                        <a
                            onClick={onView(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" />
                        </a>
                    </>
                ) : ((item.assytype === "CBU" && whs == "JTPRD") || (item.assytype === "CBU" && whs == "TGPRD") || (item.assytype === "CBU" && whs == "SDPRD")) ? (
                <>
                <a
                    onClick={onView(item)}
                    style={{
                        cursor: 'pointer',
                        color: 'orange',
                        display: 'inline-block',
                        marginRight: 20
                    }}
                >
                    <i className="fas fa-eye" />
                </a>
                <a
                    onClick={onEdit(item)}
                    style={{
                        cursor: 'pointer',
                        color: 'green',
                        display: 'inline-block',
                        marginRight: 20
                    }}
                >
                    <i className="fas fa-edit" />
                </a>
                {
                    item.productiondate && item.itemcode && item.itemdesc && item.assytype && item.lotorder && item.target
                    && item.planpriority && item.st_ave && item.operator && item.prodqtymi && item.prodgroup
                    && item.prepmin && item.processmin && item.totprocessmin && item.statusqc && item.destinationwhs && !item.statusititr
                    &&
                    <a
                        onClick={() => onSubmit(item)}
                        style={{
                            cursor: 'pointer',
                            color: '#87CEEB',
                            display: 'inline-block',
                            marginRight: 20
                        }}
                    >
                        <i className="fas fa-cogs"/>
                    </a>
                }
                </>
                ) : (item.assytype === "CBU" && whs == "JTFGA") ? (
                    <>
                        <a
                            onClick={onView(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" />
                        </a>
                        <a
                            onClick={onEdit(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'green',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-edit" />
                        </a>
                        {
                            item.productiondate && item.itemcode && item.itemdesc && item.assytype && item.lotorder && item.target
                            && item.planpriority && item.st_ave && item.operator && item.prodqtymi && item.prodgroup
                            && item.prepmin && item.processmin && item.totprocessmin && item.statusqc && item.destinationwhs && !item.statusititr
                            &&
                            <a
                            onClick={() => onSubmit(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'blue',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-truck icon-flipped" />
                            </a>
                        }
                    </>
                ) : (item.assytype === "CBU" && whs == "SDFGA") ? (
                    <>
                        <a
                            onClick={onView(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" />
                        </a>
                        <a
                            onClick={onEdit(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'green',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-edit" />
                        </a>
                        {
                            item.productiondate && item.itemcode && item.itemdesc && item.assytype && item.lotorder && item.target
                            && item.planpriority && item.st_ave && item.operator && item.prodqtymi && item.prodgroup
                            && item.prepmin && item.processmin && item.totprocessmin && item.statusqc && item.destinationwhs && !item.statusititr
                            &&
                            <a
                            onClick={() => onSubmit(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'blue',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-truck icon-flipped" />
                            </a>
                        }
                    </>
                ) : ((item.assytype === "SKD" || item.assytype === "CKD")  && (!item.statusapproval)) ? (
                    <>
                        <a
                            onClick={onViewMFI(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" />
                        </a>
                        <a
                            onClick={onEditMFI(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'green',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-edit" />
                        </a>
                    </>
                ) : ((item.assytype === "SKD" || item.assytype === "CKD")  && (item.statusapproval === "ONPROGRESS" || item.statusapproval === "COMPLETED")) ? (
                    <>
                        <a
                            onClick={() => openApproval(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'green',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="far fa-list-alt"/>
                        </a>
                        <a
                            onClick={onViewMFI(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" title='View Approval' />
                        </a>
                    </>
                ) : ((item.assytype === "SKD" || item.assytype === "CKD")  && (item.statusapproval === "ONREVISED")) ? (
                    <>
                        <a
                            onClick={() => openApproval(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'green',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="far fa-list-alt" title='View Approval'/>
                        </a>
                        <a
                            onClick={onViewMFI(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" />
                        </a>
                        <a
                            onClick={onEditMFI(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'green',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-edit" />
                        </a>
                    </>
                    ) : (<>
                    <a
                            onClick={onView(item)}
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                display: 'inline-block',
                                marginRight: 20
                            }}
                        >
                            <i className="fas fa-eye" />
                        </a>                        
                    </>)
                    
                
            
            )
        }
                    </div>
                );
            },
        },
    ];

    const onAddData = () => {
        navigate("add");
    }   


    const  propsTable = { columns, getData: getPlanProdAll, customFilter: `companycode:${localStorage.getItem('selectedCompany')}`, order: '', direction: '' };
    
    

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Planning Production</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Planning Production</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                <Overlay display={processing} />
                    <div className="row">
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='card-title'><i className='fa fa-tools' /> Planning Production List</div>
                                </div>
                                <div className='card-body' style={{overflowX: "scroll"}}>
                                    <MTable ref={mTable} {...propsTable} showIndex={true} showAddButton={false} onAddData={onAddData}/>
                                </div>
                                <div className='card-footer'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog header="Approval Detail" visible={show} resizable={false} onHide={() => setShow(false)} breakpoints={{'960px': '1115px'}} style={{width: '1115px'}}>
                <iframe id="approval-iframe" style={{width: '100%', height: '610px', border: 'none'}}></iframe>
                <div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'20%'}>Approval</th>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'15%'}>Action</th>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'20%'}>Name</th>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'15%'}>Time</th>
                                <th style={{background: '#f8f9fa', textAlign: 'center'}} width={'30%'}>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {histories.map(history => (
                                <tr>
                                    <td>{history.task_name}</td>
                                    <td>{history.action_name}</td>
                                    <td>{history.task_username}</td>
                                    <td style={{whiteSpace: 'nowrap'}}>{history.action_date}</td>
                                    <td>{history.note == null ? `-` : history.note}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Dialog>
            </section>
        </div>
    );
}

export default PlanProdScreen;